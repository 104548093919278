import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import PageNotFound from '../../components/PageNotFound';
import SEO from '../../components/seo-component/seo';
const { langs } = require('../../../languages');

export default function NotFoundPage({ location,pageContext, data }) {
  const allNotfoundData = data.allContentfulPageNotFound.nodes[0];
  const allFooterData = [
    data.allContentfulFooterMenu.nodes[0],
    data.allContentfulSiteFooter.nodes[0],
  ];

  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={data.contentfulPartnerSite}
        styles={data.contentfulPartnerTheme}
        footer={allFooterData}
        metadata={data.contentfulPartnerTheme.partnerMetaData}
        siteType={data.contentfulPartnerSite.siteType}
        searchEnabled={data.contentfulPartnerSite.enableSearch}
        astEnabled={data.contentfulPartnerSite.hasAgentSelectionToolAstRequired}
        basePath={langs[0].basePath}
        bucketName={data?.contentfulPartnerSite?.adminDetails?.s3BucketName}
        location={location}
      >
        <SEO
          data={allNotfoundData?.seoEntry}
          bucketName={data?.contentfulPartnerSite?.adminDetails?.s3BucketName}
          basePath={langs[0].basePath}
          organizationName = {data?.contentfulPartnerSite?.organizationName}
          organizationLogo = {data?.contentfulPartnerSite?.organizationLogo?.file?.url}
          organizationLinkedinLink = {data?.contentfulPartnerSite?.organizationLinkedinLink}
          organizationFacebookLink = {data?.contentfulPartnerSite?.organizationFacebookLink}
          organizationYoutubeLink = {data?.contentfulPartnerSite?.organizationYoutubeLink}
          location={location}
        />
        <PageNotFound data={allNotfoundData} basePath={langs[0].basePath}/>
      </Layout>
    </>
  );
}

export const query = graphql`
  query NotFoundPageQueryQuery($partnerCode: String!) {
    allContentfulPageNotFound {
      nodes {
        seoEntry {
          pageTitle
          metaDescription {
            metaDescription
          }
          metaRobotsTag
          ogType
          ogSiteName
          defaultOgLocale
          altimageTag
          ogImage {
            title
            file {
              url
            }
          }
        }
        node_locale
        capsuleBackgroundImage {
          file {
            url
          }
        }
        dotMatrixBackgroundImage {
          file {
            url
          }
        }
        header
        subHeader
        ctaButton {
          label
          link {
            ... on ContentfulInternalLink {
              id
              slug
              label
            }
          }
        }
      }
    }
    allContentfulFooterMenu {
      nodes {
        menuTitle1
        menuTitle2
        menuTitle3
        menuTitle4
        menuTitle5
        slugMenuTitle1
        slugMenuTitle2
        slugMenuTitle3
        slugMenuTitle4
        slugMenuTitle5
        aboutUsMenu {
          slug
          createdAt
          anchorLink
          label
        }
        solutionsMenu {
          slug
          createdAt
          anchorLink
          label
        }
        insightsMenu {
          slug
          createdAt
          anchorLink
          label
        }
        technologyMenu {
          slug
          createdAt
          anchorLink
          label
        }
        whyCartusMenu {
          slug
          label
          pdf {
            description
            file {
              url
            }
          }
          linkOrPdf
        }
        copyright
        goToTopImage {
          description
          file {
            url
          }
        }
        footerLogo {
          title
          description
          file {
            fileName
            url
          }
        }
        lgBigdonutImage {
          description
          file {
            url
          }
        }
        lgSmalldonutImage {
          description
          file {
            url
          }
        }
        mdBigdonutImage {
          description
          file {
            url
          }
        }
        mdSmalldonutImage {
          description
          file {
            url
          }
        }
        smBigdonutImage {
          description
          file {
            url
          }
        }
        smSmalldonutImage {
          description
          file {
            url
          }
        }
        xsBigdonutImage {
          description
          file {
            url
          }
        }
        xsSmalldonutImage {
          description
          file {
            url
          }
        }
      }
    }

    allContentfulSiteFooter {
      nodes {
        contactUsButton {
          label
          link {
            ... on ContentfulInternalLink {
              id
              slug
            }
            ... on ContentfulWebLink {
              id
              url
            }
          }
        }
        footerLinks {
          label
          id
          link {
            ... on ContentfulWebLink {
              url
              openInANewTab
            }
            ... on ContentfulComponentPdfWithUrl {
              pdfOrUrl
              url
              pdf {
                description
                file {
                  url
                  fileName
                }
              }
            }
            ... on ContentfulInternalLink {
              pageName
              slug
              createdAt
              anchorLink
            }
          }
        }
        rssFeedIcon {
          title
          description
          file {
            url
          }
        }
        socialMediaIcons {
          title
          description
          id
          file {
            fileName
            url
          }
        }
      }
    }
    contentfulPartnerTheme(partnerId: { eq: $partnerCode }) {
      entryTitle
      contentful_id
      buttonStyle {
        boxShadow
        buttonBackgroundColor
        entryTitle
        buttonType
        buttonFontSize
        boxShadowBlur
        boxShadowColor
        boxShadowHorizontalPosition
        boxShadowVerticalPosition
        buttonFontFamily
        googleFontFamily
      }
      entryTitle
      partnerId
      googleTagManagerId
      backgroud {
        contentful_id
        entryTitle
        bodyBackgroundColor
        footerBackgroundColor
        progressBarBackgroundColor
        progressBarBackgroundColorSecondary
      }
      grid {
        contentful_id
        gridSpacing
        gridJustifyContent
        gridAlignments
      }
      typography {
        bodyFontSize
        bodyTextColor
        contentful_id
        entryTitle
        headingColor
        headingFontFamily
        googleFontBody
        googleFontHeading
        bodyFontFamily
        headingFontSize
        h3
        h4
        h5
        h6
        headingLetterSpacing
        headingLineHeight
        linkTextColor
        footerFontSize
        footerTextColor
        footerLinkColor
        svgIconColor
      }
      partnerMetaData {
        clientNumber
        entryTitle
        hesAstBuyCode
        hesAstBuySellCode
        hesAstSellCode
        leadSource
        appType
        partner
        processor
      }
    }
    contentfulPartnerSite(partnerId: { eq: $partnerCode }) {
      entryTitle
      partnerId
      programName
      enableSearch
      hasAgentSelectionToolAstRequired
      siteType
      adminDetails {
        s3BucketName
        s3BucketSubFolder
      }
      navigation {
        primaryLogoHeader {
          description
          title
          file {
            url
            fileName
          }
        }
        donutNavigationBackground {
          description
          file {
            url
          }
        }
        donutNavigationBackgroundImage {
          description
          file {
            url
          }
        }
        searchIconBlue {
          file {
            url
          }
          description
        }
        searchIconWhite {
          file {
            url
          }
          description
        }
        secondaryNavigation {
          id
          menuLabel
          isInternalLink
          placeHolder
          menuItems {
            entryTitle
            label
            link {
              ... on ContentfulWebLink {
                id
                url
                openInANewTab
              }
              ... on ContentfulInternalLink {
                id
                slug
              }
            }
          }
        }
        menus {
          id
          menuLabel
          isInternalLink
          menuItems {
            entryTitle
            label
            link {
              ... on ContentfulInternalLink {
                slug
                pageName
                referenceToPage {
                  ... on ContentfulContactUs {
                    id
                    contentful_id
                  }
                  ... on ContentfulPageTemplate {
                    id
                    contentful_id
                  }
                }
                entryTitle
              }
            }
          }
          menuLink {
            link {
              ... on ContentfulInternalLink {
                slug
                pageName
                referenceToPage {
                  ... on ContentfulContactUs {
                    id
                    contentful_id
                  }
                  ... on ContentfulPageTemplate {
                    id
                    contentful_id
                  }
                }
              }
              ... on ContentfulWebLink {
                id
                url
                openInANewTab
              }
            }
          }
        }
        buttonAction {
          entryTitle
          id
          contentful_id
          label
        }
      }
      homePage {
        ... on ContentfulHomePage {
          entryTitle
          seoEntry {
            altimageTag
            ogImage {
              description
              file {
                url
              }
            }
            ogSiteName
            ogType
            metaDescription {
              metaDescription
            }
            pageTitle
            metaKeywords
            metaRobotsTag
            defaultOgLocale
          }
          homePageIntroSectionTopic
          homePageIntroSectionDescription
          customerTestimonialsDotMatrixImage {
            description
            file {
              url
            }
          }
          gridBrightBlueBackgroundImage {
            description
            file {
              url
            }
          }
          donutSliderBackgroundImage {
            description
            file {
              url
            }
          }
          dotMatrixBackgroundImage {
            description
            title
            file {
              url
            }
          }
          homePageIntroSection {
            header
            subHeader
            isVideo
            videoComponent {
              ...compVideo
            }
            shortDescription {
              raw
            }
            image {
              description
              title
              file {
                url
              }
            }
            ctaButton {
              buttonLabel
              link {
                link {
                  ... on ContentfulWebLink {
                    id
                    url
                  }
                }
              }
            }
          }
          homePageHeaderSection {
            subHeader
            shortDescription {
              raw
            }
            header
            image {
              description
              file {
                url
              }
            }
            ctaButton {
              buttonLabel
              link {
                link {
                  ... on ContentfulWebLink {
                    id
                    url
                  }
                }
              }
            }
          }

          homePageHeroImage {
            description
            title
            file {
              url
            }
          }
          headerDonutImage {
            file {
              url
            }
          }
          headerDotMatrixImage {
            file {
              url
            }
          }
          top
          homePageLetsTalkBackgroundImage {
            description
            file {
              url
            }
          }
          homePageLetsTalkSectionTitle {
            raw
          }
          letsTalkButtonLink {
            label
            link {
              ... on ContentfulInternalLink {
                slug
              }
              ... on ContentfulWebLink {
                url
              }
            }
          }
          homePageCustomerTestimonials {
            authorName
            authorDescription {
              raw
            }
            secondaryImage {
              description
              file {
                url
              }
            }
            authorDesignation
            authorImage {
              description
              title
              file {
                url
              }
            }
          }
          donutRightBackgroundImage {
            description
            file {
              url
            }
          }
          donutBackgroundImage {
            description
            file {
              url
            }
          }
          dotMatrixBackgroundImage {
            description
            file {
              url
            }
          }
          capsulesBackgroundImageSmalldevices {
            description
            file {
              url
            }
          }
          homePageCustomerTestimonial {
            authorDesignation
            authorImage {
              description
              title
              file {
                url
              }
            }
            secondaryImage {
              description
              file {
                url
              }
            }
            authorDescription {
              raw
            }
            authorName
          }

          sys {
            contentType {
              sys {
                id
              }
            }
          }
          dotMatrixBackgroundImage {
            description
            file {
              url
            }
          }
          capsulesBackgroundImage {
            description
            file {
              url
            }
          }
          homePageServiceTextSection {
            header
            subHeader
            shortDescription {
              raw
            }
            ctaButton {
              buttonLabel
              internalLink {
                slug
              }
            }
          }
          homePageServicesTileSection {
            backgroundImage {
              file {
                url
              }
              description
            }
            secondaryImage {
              file {
                url
              }
              description
            }
            linkOrPdf
            pdfOrDocument {
              file {
                url
              }
            }
            header {
              raw
            }
            tileLink {
              anchorLink
              slug
            }
          }
          thoughtsSectionCtaButtonLink {
            label
            link {
              ... on ContentfulInternalLink {
                slug
              }
              ... on ContentfulWebLink {
                url
              }
            }
          }
          homePageThoughtsSection {
            title
            subTitle
            arrowImage {
              description
              file {
                url
              }
              title
            }
          }
          dotMatrixBackgroundImage {
            description
            file {
              url
            }
          }
          capsulesBackgroundImage {
            description
            file {
              url
            }
          }
          homePageAwardsAndRecognitionImages {
            description
            file {
              url
            }
            title
          }
          awardsAndRecognitionHeader
          awardsAndRecognitionSubHeader
          homePageProudAssociationTitle
          homePageProudAssociationSubtitle
          homePageProudAssociationLogos {
            description
            title
            file {
              url
            }
          }
          expertiseSectionCtaButton {
            internalLink {
              slug
            }
            buttonLabel
          }
          homePageExpertiseTitleAndImage {
            title
            arrowImage {
              description
              file {
                url
              }
            }
          }
          homePageExpertiseSection {
            backgroundImage {
              description
              file {
                url
              }
            }
            linkOrPdf
            pdfOrDocument {
              description
              file {
                url
              }
            }
            header {
              raw
            }
            tileLink {
              anchorLink
              slug
            }
          }

          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
      organizationName
      organizationLogo {
        file {
          fileName
          url
        }
      }
      organizationLinkedinLink
      organizationFacebookLink
      organizationYoutubeLink
    }
  }
`;
